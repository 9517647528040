import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { NAME_STORAGE_SESSION_DATA, NAME_STORAGE_SITES_DATA } from '../const/globals';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private storageService: StorageService,
    private toastrService: ToastrService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.storageService.select(NAME_STORAGE_SESSION_DATA).subscribe(session => {
      if (!session) {
        this.router.navigate(['/']);
        return false;
      } else {
        const sites = this.storageService.selectValue(NAME_STORAGE_SITES_DATA);
        const currentUrl = state.url;
        const urls = [];
        sites.forEach(site => {
          urls.push(site.path);
        });
        urls.push('/administration');
        urls.push('/ticket');
        const includeTicket = currentUrl.includes('/administration/view-ticket');
        if (urls.filter(x => x === currentUrl).length === 0 && !includeTicket) {
          this.router.navigate(['/administration']);
          this.toastrService.error('No tienes acceso a este sitio.');
          return false;
        }
      }
    });
    return true;
  }
}