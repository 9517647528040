import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateViaAuthGuard } from './shared/guard/session.guard';

const routes: Routes = [
  { path: "auth", loadChildren: "../app/authentication/authentication.module#AuthenticationModule" },
  { path: "administration", loadChildren: "../app/administration/administration.module#AdministrationModule", canActivate: [CanActivateViaAuthGuard] },
  { path: "**", redirectTo: "auth", pathMatch: "full" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
